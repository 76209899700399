.social_icon {
    padding: 10px;
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
}
.social_icon:hover {
    stroke: #ffffff;
    background-color: rgb(255, 255, 255);
}

.social_icon path {
    fill: rgb(255, 255, 255);
}
.social_icon:hover path {
    fill: rgb(0, 0, 0);
}